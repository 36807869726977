import React, { Component } from 'react'
import { Container } from 'react-bootstrap'

import Footer from "../components/Footer"

export default class Datenschutz extends Component {
  render() {
    return (
      <div>
        <section style={{ minHeight: "100vh" }} className='pt-5 pb-5'>
          <Container>
            <h1>Datenschutzerklärung für [Deine Homepage]</h1>

            <h2>1. Allgemeines</h2>
            <p>Wir freuen uns über Ihr Interesse an unserer Website. Der Schutz Ihrer persönlichen Daten ist uns ein wichtiges Anliegen. Nachfolgend informieren wir Sie ausführlich über den Umgang mit Ihren Daten.</p>

            <h2>2. Verantwortlicher</h2>
            <p>Verantwortlicher im Sinne der Datenschutzgesetze, insbesondere der EU-Datenschutzgrundverordnung (DSGVO), ist:</p>
            <p>[Güngör Şeker Yeşilada<br />
              [Wilhelm Straße 11]<br />
              51643 Gummersbach<br />
              info@gungor-kosmetik.de</p>

            <h2>3. Verarbeitung personenbezogener Daten</h2>
            <p>Personenbezogene Daten werden auf dieser Website nur im technisch notwendigen Umfang erhoben. In keinem Fall werden die erhobenen Daten verkauft oder aus anderen Gründen an Dritte weitergegeben, es sei denn, wir sind gesetzlich dazu verpflichtet.</p>

            <h3>3.1. Kontaktformular</h3>
            <p>Auf unserer Website bieten wir die Möglichkeit, über ein Kontaktformular mit uns in Verbindung zu treten. Dabei werden folgende personenbezogene Daten erhoben:</p>
            <ul>
              <li>Name</li>
              <li>Vorname</li>
              <li>E-Mail-Adresse</li>
              <li>Nachricht</li>
            </ul>
            <p>Diese Daten werden ausschließlich zum Zweck der Bearbeitung Ihrer Anfrage bzw. Kontaktaufnahme gespeichert und verwendet. Die Datenübertragung erfolgt verschlüsselt (SSL-Zertifikat).</p>

            <h3>3.2. Cookies</h3>
            <p>Unsere Website verwendet Cookies. Dabei handelt es sich um kleine Textdateien, die auf Ihrem Endgerät gespeichert werden und die eine Analyse der Benutzung der Website ermöglichen. Cookies dienen unter anderem dazu, die Nutzungshäufigkeit und die Anzahl der Nutzer unserer Website zu ermitteln, sowie unser Angebot für Sie komfortabler zu gestalten.</p>
            <p>Sie können das Setzen von Cookies durch entsprechende Einstellungen in Ihrem Browser jederzeit verhindern. Bereits gespeicherte Cookies können ebenfalls in den Einstellungen Ihres Browsers gelöscht werden. Bitte beachten Sie jedoch, dass dies die Funktionalität unserer Website einschränken kann.</p>

            <h3>3.3. Google Fonts</h3>
            <p>Zur Darstellung von Schriftarten verwenden wir Google Fonts. Die Einbindung von Google Fonts erfolgt durch einen Serveraufruf bei Google (in der Regel in den USA). Dabei wird Ihre IP-Adresse sowie die URL der Website, auf der Google Fonts eingebunden ist, an einen Server von Google übertragen und dort gespeichert. Weitere Informationen zu Google Fonts finden Sie in den Datenschutzhinweisen von Google: 
              <a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a></p>

            <h2>4. Ihre Rechte</h2>
            <p>Sie haben das Recht auf Auskunft, Berichtigung, Löschung, Einschränkung der Verarbeitung, Datenübertragbarkeit, Widerruf und Widerspruch. Wenn Sie Fragen hierzu haben oder eines der genannten Rechte ausüben möchten, können Sie sich jederzeit an uns wenden.</p>

            <h2>5. Aktualität und Änderung dieser Datenschutzerklärung</h2>
            <p>Diese Datenschutzerklärung ist aktuell gültig und hat den Stand [Datum]. Durch die Weiterentwicklung unserer Website oder aufgrund geänderter gesetzlicher bzw. behördlicher Vorgaben kann es notwendig werden, diese Datenschutzerklärung zu ändern. Die jeweils aktuelle Datenschutzerklärung finden Sie auf unserer Website.</p>
          </Container>
        </section>

        <Footer />
      </div>
    )
  }
}
