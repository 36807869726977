import React, { Component } from 'react'
import { Container, Nav, Navbar } from 'react-bootstrap'


export default class NavbarHeader extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Navbar expand="lg" className='header-nav fixed-top'>
        <Container>
          <Navbar.Toggle style={{ color: "white" }} />
          <Navbar.Collapse>
            <Nav className='mx-auto'>
              <Nav.Link className='header-nav-link' onClick={e => {
                e.preventDefault();
                this.props.scrollToId("aboutus-sec");
              }}>Über Uns</Nav.Link>
              <Nav.Link className='header-nav-link' onClick={e => {
                e.preventDefault();
                this.props.scrollToId("ourstudio");
              }}>Unser Studio</Nav.Link>
              <Nav.Link className='header-nav-link' onClick={e => {
                e.preventDefault();
                this.props.scrollToId("services");
              }}>Dienstleistungen</Nav.Link>
              <Nav.Link className='header-nav-link' onClick={e => {
                e.preventDefault();
                this.props.scrollToId("contact");
              }}>Kontakt</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    )
  }
}
