module.exports = [
    {
        "image": "/images/services/1.jpeg",
        "title": "Dauerhafte Haarentfernung (Laser, Wachs, Epilation)",
        "subTitle": "Soprano ICE ist die effektivste und umfassendste Lösung zur Laser-Haarentfernung für alle Haut- und Haartypen.",
        "price": "",
        "backgroundPosition": "start center",
        "modalContent": `
            <p>
            Sopranoie<br />
            Pretty.Cool.<br />
            <span style={{ fontWeight: 'bold' }}>
            BEREIT, HAUT ZU ZEIGEN - DAS GANZE JAHR ÜBER?
            </span><br />
            Erleben Sie Soprano ICE zur praktisch schmerzfreien<br />
            Laser-Haarentfernung<br />
            Nachweislich sicher<br />
            Schnelle Behandlung Für alle Hauttypen,<br />
            sogar für gebräunte Haut            
            </p>
            <p>
            Schnelle und komfortable Haarentfernung - Ihre Vorteile
            Praktisch schmerzfrei
            Die Behandlung ist praktisch schmerzfrei und fühlt sich fast wie eine Massage an.
            Alle Haut- und Haartypen
            Wirksam bei hellerem und dunklem sowie dickem und dünnem Haar.
            Klinisch erwiesene Sicherheit
            Auch für dunklere Haut.
            Gebräunte Haut
            Die Behandlung kann das ganze Jahr über durchgeführt werden, auch auf gebräunter Haut.
            Schnell
            Die Behandlung ist sehr schnell, selbst bei großen Arealen wie Beinen oder Rücken.
            Keine Ausfallzeit
            Sie können sofort wieder Ihren gewohnten Tätigkeiten nachgehen.
            Perfekt für den ganzen Körper Einschließlich empfindlicher oder schwer zu erreichender Areale wie Ohren, Nasenlöcher, Augenbrauen und Bikinizone mit einem speziellen Behandlungsaufsatz.
            </p>

            <p>
            FÜHLBAR GLATTE, HAARFREIE HAUT
            Soprano ICE ist die effektivste und umfassendste Lösung zur Laser-Haarentfernung für alle Haut- und Haartypen.
            Unerwünschte Körperbehaarung loszuwerden ist nicht immer leicht. Betrachtet man das breite Spektrum an Hautfarben und Haartypen sowie weitere Herausforderungen wie langwierige oder schmerzhafte Behandlungsmethoden mit unvorhersehbaren Ergebnissen, kann man sich schnell entmutigen lassen.
            Doch dank einem Durchbruch in der Forschung und Entwicklung bei Alma Lasers bietet der Laser Soprano ICE jetzt eine ganz einfache Lösung zur wirksamen Haarentfernung, die genau auf Ihre individuellen Bedürfnisse abgestimmt ist.
            Ob helle, dunklere oder leicht gebräunte Haut, dickes oder dünnes Haar, Soprano ICE entfernt das Haar vollständig und hochwirksam: sicher, schnell und - was das Beste ist - praktisch schmerzfrei.
            Die Technologie

            <ul>
            <li>Jeder Haar- und Hauttyp reagiert am besten auf eine bestimmte Laser-Wellenlänge. Soprano ICE vereint die Vorteile mehrerer Wellenlängen und Technologien und bietet so stets eine individuelle Behandlungslösung.</li>
            <li>Die SHR-Technologie erwärmt die Haut schrittweise auf eine Temperatur, die die Haarwurzeln wirksam schädigt und ein Nachwachsen verhindert.            </li>
            <li>Die In-motion™️-Technik hält den Laser konstant in Bewegung und sichert eine vollständige Abdeckung der Behandlungsfläche            <br />
            das verbessert die Ergebnisse bei kürzerer Behandlungszeit.</li>
            <li>Die ICE™️-Technologie schützt und kühlt die Haut auf angenehme Weise - die Behandlung ist damit praktisch schmerzfrei und dennoch wirksam.            </li>
            <li>Verschiedene Handstücke ermöglichen die Behandlung aller Teile des Körpers, einschließlich schwer zugänglicher und empfindlicher Areale.            </li>
            </ul>
            </p>

            <p>
            <span style={{ fontWeight: 'bold' }}>
            VOR UND NACH DER SEHANDLUNG WAS MÜSSEN SIE BEACHTEN?
            </span<br />
            Nicht zupfen
            Mehrere Wochen vor der Therapie sollten die Haare nicht gebleicht, gezupft, gewachst oder epiliert werden.
            Sonne meiden
            Zwar ist die Behandlung von gebräunter Haut mit Soprano ICE Platinum möglich, die besten Ergebnisse werden jedoch auf ungebräunter Haut erzielt, da eine höhere Energie verwendet werden kann. Auch nach der Behandlung sollten Sie direkte Sonnenbestrahlung und das Solarium für ca. 4 Wochen meiden, um die Haut nicht unnötig zu reizen.
            Sonnenschutz verwenden
            Kann die direkte Sonnenbestrahlung nicht vermieden werden, sollte auf den behandelten Bereich eine Sonnenschutzcreme mit möglichst hohem Lichtschutzfaktor aufgetragen werden.
            Medikamente und Schwangerschaft
            Es dürfen keine Medikamente eingenommen werden, die zu einer höheren Lichtempfindlichkeit führen und es darf keine Schwangerschaft vorliegen.
            Ggf. ist eine Herpesprophylaxe durchzuführen.
            Mehrere Behandlungen nötig
            Die Behandlung ist nur dann wirksam, wenn das Haar sich in der Wachstumsphase befindet. Da diese von Haar zu Haar unterschiedlich verläuft, sind für ein optimales Ergebnis mehrere Behandlungen erforderlich.
            </p>
        `
    },
    {
        "image": "/images/services/2.jpeg",
        "title": "Hautverjüngung",
        "price": ""
    },
    {
        "image": "/images/services/3.jpeg",
        "title": "Gesichtsbehandlung",
        "price": ""
    },
    {
        "image": "/images/services/4.jpeg",
        "title": "Anti-Aging",
        "price": ""
    },
    {
        "image": "/images/services/5.jpeg",
        "title": "Aromatherapie",
        subTitle: '',
        "price": "",
        modalContent: `
            <p>
            Ziel der Aromatherapie ist es, die Stimmung und den allgemeinen Gesundheitszustand einer Person zu verbessern, indem die Dämpfe ätherischer Öle eingeatmet oder auf die Haut aufgetragen werden. Bei der Massagetherapie werden ätherische Öle mit Trägerölen gemischt und auf die Haut aufgetragen, mit dem Ziel, die Muskeln zu entspannen und Stress abzubauen.
            </p>
        `
    },
    {
        "image": "/images/services/6.jpeg",
        "title": "Augenbrauen zupfen",
        subTitle: '',
        "price": "",
        modalContent: `
            <p>
                "Beim Zupfen mit Faden /wax hält das Ergebnis länger als bei der Haarentfernung mit der Pinzette, da alle Haare in allen Wachstumszyklen entfernt werden,"
            </p>
            <p>
                Beim Augenbrauendesign im Goldenen Schnitt werden die Augenbrauen entsprechend den natürlichen Proportionen des Gesichts gestaltet. Dank dieses Verfahrens kommen auch andere Gesichtszüge klarer zur Geltung.
                Darüber hinaus sieht das Gesicht jünger und lebendiger aus, da die Augenbrauen ein natürlicheres Aussehen erhalten und die Symmetrie des Gesichts zunimmt.
            </p>
        `
    },
    {
        "image": "/images/services/7.jpeg",
        "title": "Permanent Make-Up (Lippen, Eyeliner, Augenbrauen)",
        subTitle: '',
        modalContent: `
            <p>
                <span style={{ fontWeight: 'bold' }}>AUGENBRAUEN</span><br />
                - perfektionierter Schwung durch natürliche Härchenzeichnung formgebende Schattierung symmetrische Achsenkorrektur<br />
                - „Highlight" Spezialtechnik<br />
                - Öffnet den Blick<br />
                - Auffüllen fehlender Härchen<br />
                - 3D-Technik bei fehlenden Brauen<br />
                <br /><br />
                <span style={{ fontWeight: 'bold'}}>AUGENLIDER</span><br />
                - optimal korrigieren und betonen: formoptimierender Eyeliner<br />
                - Wimpernverdichtung für Tiefe und Ausdruck<br />
                - verführerische „Smoky Eyes"<br />
                - optisches Anti-Aging durch „Highlights" optische Schlupflid-Korrektur <br />
                
                <br /><br />
                <span style={{ fontWeight: 'bold' }}>LIPPEN</span>
                <br />
                - vollkommene Lippenkontur<br />
                - sinnliche Teil- oder Vollschattierung<br />
                - „Lip-Light" zaubert Volumen und Form<br />
                - Korrektur bei Narben<br />
            </p>

            <p>
                <span style={{ fontWeight: 'bold'}}>Kennen Sie das auch?</span><br />
                <ul>
                    <li>Sie sind Brillenträger und haben Schwierigkeiten, sich selbst zu schminken?</li>
                    <li>Ungeschminkt wirken Ihre Augen müde und glanzlos, die Lippen blass und konturlos?</li>
                    <li>Sie wünschen sich dichtere Wimpern für einen intensiveren, strahlenderenBlick?</li>
                    <li>Sie träumen von einem sicheren Auftreten und mehr Selbstbewusstsein?</li>
                    <li>Sie möchten endlich Ihre Herpes-Narben an der Lippenkontur dauerhaft verschwinden lassen?</li>
                    <li>Der Wunsch, auch am Strand, im Schwimmbad, nach und während dem Sport immer perfekt auszusehen?</li>
                    <li>Jahrelanges Zupfen hat Ihre Augenbrauen aus der Form gebracht und Sie • träumen davon, Ihren Brauen wieder eine schön geschwungene Kontur zu verleihen?</li>
                    </ul><br />

                Ihr Wunsch ...<br />
                Sie möchten einfach JEDERZEIT, ÜBERALL und zu JEDER GELEGENHEIT top gepflegt und perfekt aussehen?<br />
                Ihre Schönheit typgerecht & dauerhaft unterstreichen?<br />
                Junger und dynamischer wirken?<br />
                Mehr Selbstsicherheit und Erfolg durch mehr Ausstrahlung?<br />
                Bei uns wird dieser Traum wahr -<br />
            </p>
            <p>
            Augenbrauen<br/>
            Natürliche<br />
            Schönheit betonen und unterstreichen - Mikropigmentation von Goldeneye!
            Die Augenbrauen werden als Ausdrucks-träger eines Gesichts oft unterschätzt. Sie geben dem Gesicht einen Rahmen und geben wichtige Linien und emotionale Züge vor.
            Erst der perfekte Schwung öffnet den Blick optimal und gibt dem Gesicht einen eleganten Zug.
            Es wird hier mit verschiedensten Strichtechniken gearbeitet, um eine optisch natürliche Härchenzeichnung zu erreichen. Teilweise werden mehrere Farbschattierungen eingesetzt, um diesen Effekt noch zu verstärken. Während der Arbeit wird immer versucht, beide Brauen in Höhe und Form einander anzuglei-chen, um eventuelle Ungleichheiten zu kaschieren und dem Gesicht die grösst-mögliche Symmetrie zu verleihen, die, wie…
            </p>
            <p>
            Augen,<br/>
            Für ein frischeres, jüngeres Aussehen - optisches Anti-Aging mit Goldeneye!
            Die Augen sind sprichwörtlich das Tor zur Seele. Blickkontakt entscheidet in sekundenbruchteilen über Sympathie und Verbundenheit. Die Möglichkeiten, Ihre Augen zu betonen und verschönern, sind gross. Von der dezenten, natürlichen Wimpernverdichtung über den Eyeliner bis hin zum sexy Smokey-Eye - es gibt eine grosse Auswahl an Möglichkeiten und Variationen.
            Optisches Anti-Aging, also verjüngende und korrigierende Techniken, gehören selbstverstandlich auch zum Repertoire. Als solche nicht erkennbar gleicht die korrigierende Mikropigmentation mit feinster Strichführung Augenachsen aus und sorgt für Kontrast und Tiefe im Blick.
            Bei der optischen Wimpernverdichtung werden feinste Pünktchen zwischen die Wimpern gesetzt. Die Wimpernpartie wirkt so voller und dunkler, der ganze Blick wird ausdrucksstärker. Der Eyeliner verstärkt den Effekt der Wimpernverdichtung noch etwas und kann z.B. für Brillenträgerinnen ein wahrer Segen sein. Das berühmte Smokey-Eye lehnt sich an die gleichnamige Schminktechnik an und verleiht dem Auge etwas Geheimnisvolles, da es die Augenachse leicht nach oben korrigiert.
            Zertifiziert und ausgezeichnet Goldeneye Pigmente verbinden Verträglichkeit mit Vielseitigkeit. Sie sind zertifiziert und amtlich zugelassen.
            </p>

            <p>
            Lippen •<br />
            Nicht umsonst sind Lippen der ultimative Ausdruck von Sinnlichkeit. Viele leiden unter den Folgen von Herpes-Narben,
            "ausgefransten" Lippenkonturen oder auch schlicht fehlendem Volumen. Mit verschiedensten Schattiertechniken und einer Vielzahl an Farbnuancen erreicht man eine optimale Korrektur von Form, Farbe und Volumen. Das Resultat: vollkommen natürliche und wunderschöne Lippen.
            ParaMEDical Treatments
            Dieser Spezialbereich der Micropigmentation erfordert meisterliches Know-How, langjährige Erfahrung, Fingerspitzengefühl und viel Talent. Nur ein Top-Pigmentist kann diese Auflagen erfüllen, die absolut notwendig sind, um z.B. die authentische 3D-Zeichnung einer Mamillenrekonstruktion zu erreichen, um bei einer Vitiligo-Abdeckung oder Narbenkorrektur den passenden Hautton zu treffen.
            Diese Behandlungen sind zeitaufwendiger, da sie z.B. Farbtests erfordern und intensivere Beratungszeit benötigen. Das ist absolut unumgänglich, da hier nicht nur der Körper im Fordergrund steht, sondern im Besonderen die Seele. Das Ergebnis soll ein neues und stolzes Körpergefühl schenken ... oder ein verloren geglaubtes zurückgeben.
            </p>

            <p>
            PigMANtation<br />
            markanter Blick®️<br />
            durch Wimpernverdichtung und Pünktchenzeichnung
            Die speziell für Männer entwickelte Goldeneye PigMANtation ist einzigartig.
            Licht- und Schattentechniken betonen Ihre Lippen, korrigieren die Augenbrauen und sorgen für einen magischen (Augen)blick. Absolut dezent und natürlich fur einen markanten, erfolgreichen und definierten Look.
            dezente Betonung. sinnliche, maskuline Lippen durch Aufheller & Schattenzeichnung unten
            Skalp PigMANtation
            Diese neuartige Form der Pigmentierung ist vollkommen natürlich, dezent und für jede Form der Kurzhaarfrisur geeignet. In dieser Form wird diese Art der Pigmentierung in Deutschland bisher exklusiv von Goldeneye Pigmentisten praktiziert. Nur durch diese Technik ist die absolute Natürlichkeit und Sicherheit gewährleistet. Lassen Sie sich unverbindlich beraten. Immer mehr Männer sind überzeugt.
            Original-Zitat von Andy, 29 Jahre:
            ... Die Behandlung war nicht sehr schmerzhaft. An manchen Stellen etwas mehr, jedoch im ganzen eigentlich nicht schlimm. Es dauerte weniger als 3 Stunden, und als ich danach in den Spiegel schaute konnte ich gar nicht glauben, was ich da sah: Ich hatte plötzlich einen Haaransatz! Es sah unglaublich aus..
            </p>

            <p>
            Die Technik & Kunst von Goldeneye<br />
            Augenbrauen (natürliche Härchenzeichnung)<br />
            Schlupflidtechnik - Smokey Eyes innerer Lidstrich (Kajal)<br />
            Wimpernverdichtung<br />
            Highlights<br />
            Lippenkontur und -schattierung<br />
            Lip-Light<br />
            a kind of<br />
            MAGIC<br />
            Anti-Aging Treatments (optisches Augenbrauen-Lifting)<br />
            natürlich & dezent (PigMANtation)<br />
            korrigieren und Symetrie ausgleichen (Augenachsen, Mundwinkel ...)<br />
            </p>
        `,
        "price": ""
    },
    {
        "image": "/images/services/8.jpeg",
        "title": "Microblading (Lippen, Eyeliner, Augenbrauen)",
        "price": ""
    },
    {
        "image": "/images/services/9.jpeg",
        "title": "Powderbrows",
        "price": ""
    },
    {
        "image": "/images/services/10.jpeg",
        "title": "Wimpernlifting",
        "price": ""
    },
    {
        "image": "/images/services/11.jpeg",
        "title": "Augenbrauenlifting",
        "price": ""
    },
    {
        "image": "/images/services/12.jpeg",
        "title": "Make-Up (für jeden Anlass)",
        "price": ""
    },
    {
        "image": "/images/services/13.jpeg",
        "title": "Nagel- und Fußpflege",
        "price": ""
    }
];