import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'


export default class MoreDetailsServiceModal extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        var { selectedService } = this.props;
        if (selectedService == null) return null;
        return (
            <>
                <Modal.Header closeButton>
                    <Modal.Title>{selectedService.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h6 style={{ fontWeight: 'bold' }}>
                        {selectedService.subTitle}
                    </h6><br />
                    <div dangerouslySetInnerHTML={{ __html: selectedService.modalContent }}></div>
                </Modal.Body>
            </>
        )
    }
}
