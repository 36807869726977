import React, { Component } from 'react'

import NavbarHeader from "../components/NavbarHeader"
import { Form, Card, Col, Container, Image, Row, Button, Modal } from 'react-bootstrap'

import Footer from "../components/Footer";

import services from "../utils/services_data"
import { FaFilePdf } from 'react-icons/fa';

import MoreDetailsServiceModal from '../components/MoreDetailsServiceModal'

export default class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedService: null,
      isMoreDetailsModalOpen: false
    };
  }

  scrollToId(id) {
    var element = document.getElementById(id);
    if (element) {
      const offsetTop = element.getBoundingClientRect().top + window.pageYOffset - 50;
      window.scrollTo({ top: offsetTop, behavior: 'smooth' });
    }
  }

  isDateEqualsNow(day, month, year) {
    var date = new Date();

    var dayNow = date.getDate();
    var monthNow = date.getMonth() + 1;
    var yearNow = date.getFullYear();

    return day >= dayNow && monthNow >= month && yearNow >= year ? true : false;
  }

  render() {
    var sectionPadding = document.getElementsByClassName("header-nav").height;

    return (
      <div>
        <section id='home-sec' style={{
          backgroundImage: "url('" + process.env.REACT_APP_WEB_URL + "/images/Bild-1.jpg')"
        }}>
          <NavbarHeader scrollToId={id => this.scrollToId(id)} />

          <Container className='pt-5'>
            <Image src='/images/logo.png' className='logo'
              height={200} />
          </Container>
        </section>


        <section id='aboutus-sec'>
          <div style={{ minHeight: '7vh', backgroundColor: '#000' }}></div>
          <Container fluid>
            <Row style={{ minHeight: '95vh' }}>
              <Col md={6} className='aboutus-firstcol-left'
                style={{
                  backgroundImage: "url(" + process.env.REACT_APP_WEB_URL + "/images/aboutus_person.jpg)"
                }}>
              </Col>
              <Col md={6} xs={12} style={{ paddingTop: '180px' }}>
                <h2 className='sec-title'>WILLKOMMEN BEI GÜNGÖR KOSMETIK</h2>
                <p className='text-center'>
                  Bei Güngör Kosmetik sind wir leidenschaftlich darum bemüht, die natürliche Schönheit jedes Einzelnen hervorzuheben und zu pflegen.
                  Seit unserer Gründung setzen wir auf hochwertige Beauty-, Kosmetik- und Körperpflegeprodukte, die nicht nur ästhetisch ansprechend sind,
                  sondern auch auf wissenschaftlicher Expertise basieren.
                </p>

                <br /><br />
                <h2 className='sec-title'>Unsere Vision</h2>
                <p className='text-center'>
                  Unsere Vision ist es, Menschen zu inspirieren, sich selbst zu lieben und sich in ihrer Haut wohlzufühlen. Wir glauben an die transformative Kraft von Selbstpflege und sind fest davon überzeugt, dass wahre Schönheit von innen kommt. Daher entwickeln wir Produkte, die nicht nur äußerlich verschönern, sondern auch das innere Wohlbefinden fördern.
                </p>
                <div className='d-flex justify-content-center'>
                  <Button
                    onClick={e => {
                      e.preventDefault();
                      this.scrollToId("services");
                    }}
                    variant='block' style={{ backgroundColor: "#f2b98d", color: "white" }}>
                    Dienstleistungen
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section id='ourstudio'>
          <Container>
            <h2 className='sec-title text-center'>Unser Studio</h2>
            <p className='text-center'>
              Wir laden dich ein, Teil unserer Beauty-Community zu werden.
              Gemeinsam möchten wir auf eine Reise zur Selbstentfaltung
              und Pflege gehen.
              Entdecke unsere Produktpalette, lass dich von unseren Beauty-Tipps inspirieren und teile deine eigene
              Reise zur Schönheit mit uns.
            </p>
          </Container>
        </section>


        <section id='services'>
          {!this.isDateEqualsNow(17, 12, 2024) ? (
            <section style={{ backgroundColor: 'red', color: 'white' }}>
              <Container>
                <p
                  stlye={{ fontWeight: 'bold' }}
                  className='pt-2 pb-2 text-center text-uppercase'>
                  Aktionsrabatt bis zum 17.12.2024
                </p>
              </Container>
            </section>
          ) : null}

          <Container>
            <h2 className='pt-2 sec-title text-center'>Unsere Dienstleistungen</h2>
            <div className='d-flex justify-content-center pt-3 pb-3'>
              <Button
                onClick={async (e) => {
                  e.preventDefault();
                  window.location.href = process.env.REACT_APP_WEB_URL + '/docs/catalog.pdf';
                }}
                style={{ color: "white", backgroundColor: '#f2b98d' }}>
                <FaFilePdf style={{ marginRight: 10 }} /> KATALOG ÖFFNEN (PDF)
              </Button>
            </div>

            <Row>
              {services.map((item, index) => {
                return (
                  <Col md={3} className='mt-2 mb-2'>
                    <Card className="text-white"
                      style={{ height: 350, boxShadow: "none", outline: "none", border: "none" }}>
                      <Card.Img
                        style={{
                          backgroundImage: "url('" + item.image + "')",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                          height: 250,
                          backgroundPosition: item.backgroundPosition != undefined ? item.backgroundPosition : "center center"
                        }}
                        height={200} />
                      <Card.ImgOverlay style={{
                        position: "relative",
                        bottom: 0,
                        padding: 0, margin: 0,
                        border: "none", outline: "none", boxShadow: "none",
                        background: "black", height: "auto"
                      }}>
                        <div className='text-center m-3'>
                          <p>
                            <span className='fw-bold'>{item.title}</span> <br />
                            {item.modalContent != undefined ? (
                              <Button variant='block'
                                onClick={e => {
                                  e.preventDefault();
                                  this.state.selectedService = item;
                                  this.state.isMoreDetailsModalOpen = true;
                                  this.forceUpdate();
                                }}
                                className='w-100 mt-2'
                                style={{
                                  backgroundColor: "#f2b98d",
                                  color: "black", textTransform: 'uppercase'
                                }}>
                                Mehr erfahren
                              </Button>
                            ) : null}
                          </p>
                        </div>
                      </Card.ImgOverlay>
                    </Card>
                  </Col>

                )
              })}
            </Row>
          </Container>
        </section>

        <section id='contact'>
          <Container>
            <Row className='pt-2 pb-2'>
              <Col className='pt-2 pb-2' md={6}>
                <h2 className='sec-title text-start'>Kontakt</h2>
                <Form method='POST' onSubmit={async (e) => {
                  e.preventDefault();
                  var { firstname, lastname, email, message } = e.target;

                  var confirm = window.confirm("Willst du wirklich den Kontaktformular absenden?");
                  if (confirm) {
                    try {
                      var response = await fetch('https://api.gungor-kosmetik.de/api/v1/form/processContactForm', {
                        method: 'POST',
                        headers: {
                          'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                          firstname: firstname.value,
                          lastname: lastname.value,
                          email: email.value,
                          message: message.value
                        })
                      });

                      var data = await response.json();
                      if (data.failed == false) {
                        alert("Vielen Dank für deine Nachricht!");
                      }
                    } catch (e) {
                      alert("Es ist ein Fehler aufgetreten!");
                    }
                  }
                }}>
                  <Row>
                    <Col md={6}>
                      <Form.Group className='pt-3 pb-3'>
                        <Form.Label>Ihr Vorname</Form.Label>
                        <Form.Control type='text'
                          placeholder='Ihr Vorname'
                          name='firstname'
                          className='input' />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className='pt-3 pb-3'>
                        <Form.Label>Ihr Nachname</Form.Label>
                        <Form.Control type='text'
                          name='lastname'
                          placeholder='Ihr Nachname'
                          className='input' />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group className='pt-3 pb-3'>
                    <Form.Label>Ihr E-Mail adresse</Form.Label>
                    <Form.Control type='email'
                      placeholder='Ihre E-Mail Adresse'
                      name='email'
                      className='input' />
                  </Form.Group>
                  <Form.Group className='pt-3 pb-3'>
                    <Form.Label>Ihre Nachricht</Form.Label>
                    <Form.Control type='text'
                      name='message'
                      className='input' placeholder='Ihre Nachricht' />
                  </Form.Group>
                  <Form.Group>
                    <Button type='submit' variant='block'
                      style={{ backgroundColor: "#f2b98d", color: "white" }}>
                      Absenden
                    </Button>
                  </Form.Group>
                </Form>
              </Col>
              <Col className='pt-2 pb-2' md={6}>
                <div className='mb-contact-col2' >
                  <p>
                    <span className='fw-bold'>Anschrift</span> <br />
                    <span>
                      Güngör Kosmetik <br />
                      Wilhelm Straße 11<br />
                      51643 Gummersbach<br />
                      Deutschland
                    </span>

                  </p>
                  <p>
                    <span className='fw-bold'>Öffnungszeiten</span> <br />
                    <table className='w-100'>
                      <tbody>
                        <tr>
                          <td>Di. - Do.</td>
                          <td>10:00 - 18:00</td>
                        </tr>
                        <tr>
                          <td>Mo., Fr. & Sa.</td>
                          <td>Nur mit Termin</td>
                        </tr>
                        <tr>
                          <td>Sonntag</td>
                          <td>Geschlossen</td>
                        </tr>
                      </tbody>
                    </table>
                  </p>

                  {/* <iframe
                    frameborder="0"
                    scrolling="no"
                    marginheight="0"
                    marginwidth="0"
                    id="gmap_canvas"
                    src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=Wilhelm%20Stra%C3%9Fe%2011%20Gummersbach+(G%C3%BCng%C3%B6r%20Kosmetik)&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe> */}


                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <Footer />

        <Modal show={this.state.isMoreDetailsModalOpen}
          size='lg' onHide={() => {
            this.state.selectedService = null;
            this.state.isMoreDetailsModalOpen = false;
            this.forceUpdate();
          }} animation>
          <MoreDetailsServiceModal selectedService={this.state.selectedService} />
        </Modal>
      </div>
    )
  }
}
